<script>
  import {
    required,
    helpers
  } from "@vuelidate/validators";
  import useVuelidate from "@vuelidate/core";


  import appConfig from "../../../../app.config";
  import Swal from "sweetalert2";
  import axios from 'axios';
  import animationData from "@/components/widgets/msoeawqm.json";
  import animationData1 from "@/components/widgets/gsqxdxog.json";
  import giornaliere from "@/components/widgets/worksites/giornaliere";

  import moment from 'moment'
  import serverConfig from '@/helpers/config';
  
  
  export default {
    name:'pianificazioneLavori',
    props: [
        'projectID',
        'worksiteID',
    ],
    setup() {
      moment.locale('it')
    
    
      let tk = 'Bearer '+localStorage.getItem('tk')


      return { tk, v$: useVuelidate()};
    },
    page: {
      title: "Lista cantieri",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        addTabPlan:false,
        rangeDateconfig: {
          wrap: true, // set wrap to true only when using 'input-group'
          altFormat: "M j, Y",
          altInput: true,
          dateFormat: "d M, Y",
          mode: "range",
        },
        config: {
          wrap: true, // set wrap to true only when using 'input-group'
          altFormat: "M j, Y",
          altInput: true,
          dateFormat: "d M, Y",
        },
        timeConfig: {
          enableTime: false,
          dateFormat: "d M, Y",
        },
        filterdate: null,
        filterdate1: null,
        filtervalue: 'All',
        filtervalue1: 'All',
        filtersearchQuery1:null,
        date: null,
        submitted: false,
        regError: null,
        tryingToRegister: false,
        isRegisterError: false,
        registerSuccess: false,
        item:{
            projectID:this.projectID,
            worksiteID:this.worksiteID,
            startStr:"",
            endStr:"",
            name:"",
            details:"",
        },
        optionsFiscalBenefit:[],
        optionsFSPrivate:[
          { value: '', label: 'Seleziona beneficio fiscale' },
          { value: 'conto_termico', label: 'Conto termico' },
          { value: 'detrazione_fiscale', label: 'Detrazione fiscale' },
        ],
        optionsFSBusiness:[
          { value: '', label: 'Seleziona beneficio fiscale' },
          { value: 'credito_imposta', label: "Credito d'imposta" },
          { value: 'sconto_in_fattura', label: 'Sconto in fattura' },
        ],
        optionsFSPA:[
          { value: 'non_previsto', label: 'Non previsto' },
        ],
        allTask: [
        ],
        searchQuery: null,
        page: 1,
        perPage: 50,
        sortRes:'asc',
        value: 'All',
        pages: [],
        responseList: [
        ],
        paginated:{},
        select:'all',
        search:'',
        defaultOptions: {
          animationData: animationData
        },
        defaultOptions1: {
          animationData: animationData1
        },
        minDate:moment().format('YYYY-MM-DD'),
        maxDate:new Date().getFullYear().toString() + '-12-31',
        minDateEnd:moment().format('YYYY-MM-DD'),
        maxDateEnd:new Date().getFullYear().toString() + '-12-31',
      };
    },
    components: {
        giornaliere,
     
    },
    validations: {
      item: {
        source: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        customerCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemSubCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        fiscalBenefit: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
      },
    },
    computed: {
      
    },
    watch: {
      allTask() {
        this.setPages();
      },
    },
    created() {
      this.setPages();
    },
    filters: {
      trimWords(value) {
        return value.split(" ").splice(0, 20).join(" ") + "...";
      },
    },
    beforeMount() {
      
    },

    methods: {
      convertDatetime(date){
        if (date){
          return  moment(date).format('DD/MM/YYYY HH:MM') 
        }
      },
      axiosInterceptor(){
        axios.interceptors.response.use(
            (response) => {
                
                return response;
            },
            (error) => {
                if (error.response && error.response.status === 401) {
                  Swal.fire({
                    title:"Accesso non autorizzato",
                    text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }
                    return Promise.reject('Unauthorized access');
                } else if (error.response && error.response.status  === 404) {
                    Swal.fire({
                      title:"Nessun risultato trovato",
                      text:"Mi spiace, nessun risultato trovato",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('The server cannot find the requested resource');
                }  else if (error.response && error.response.status  === 412) {
                  Swal.fire({
                    title:"Credenziali mancanti",
                    text:"Inserisci delle credenziali valide, per favore.",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              } else if (error.response && error.response.status  === 423) {
                    Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }                
                    return Promise.reject('The server cannot find the requested resource');
                }
                else if (error.response && error.response.status  === 500) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Internal Server Error');
                }
                else if (error.response && error.response.status  === 502) {
                    Swal.fire({
                      title:"Errore Server",
                      text:"Mi spiace, ma riscontriamo degli errori sul server.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('Bad Gateway');
                }
                else if (error.response && error.response.status  === 503) {
                  Swal.fire({
                    title:"Servizio non disponibile",
                    text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Service Unavailable');
                } else {
                  console.log(error.response.status )
                  Swal.fire({
                    title:"Errore imprevisto",
                    text:"Ops! si è verificato un problema...riprova, per favore.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  
                }

            }
          );
      },
      getList(){
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}worksites/task/by-worksite/${this.worksiteID}`, 
          { 
            params: { 
              perPage: this.perPage,
              page: this.page,
              sortRes: this.sortRes,
              select: this.select,
              search: this.search,
            }, 
            headers: { 'authorization':this.tk}
          }
        ).then(response=>{
          this.responseList = response.data.results.data;
          this.paginated = response.data.results
          })
      },
      SearchData(){
        this.searchQuery= this.filtersearchQuery1;
        this.filterdate = this.filterdate1;
        this.filtervalue = this.filtervalue1;
      },
      filterOptions(val){
        switch(val){
          case "azienda":
            this.optionsFiscalBenefit = this.optionsFSBusiness
            break;
          case "privato":
            this.optionsFiscalBenefit = this.optionsFSPrivate
            break;
          case "pubblico":
            this.optionsFiscalBenefit = this.optionsFSPA
            break;
        }        
      },
      editdata(data) {
        this.$router.push('/worksite/detail/'+data)
      },

      addnew() {
        document.getElementById("addform").reset();
        document.getElementById('exampleModalLabel').innerHTML = this.$t('t-new-project');
        document.getElementById('add-btn').style.display = 'block';
       
      },
      updateDate(type){
        if (type=='start'){
          this.minDateEnd = this.item.startStr
          this.item.endStr = this.item.startStr
        }
        if (type=='end'){
          this.minDateEnd = this.item.startStr
        }
      },
      initItem(){
       
        if (this.item.startStr!=''||this.item.endStr) {
          this.axiosInterceptor()
          this.submitted = true;
          
          this.v$.$touch();
          if (this.item.startStr!='' && this.item.endStr)
          { 
            if (this.$route.name=='support-detail'){
              this.item.task_type = 'task-assistenza'
            }
            if (this.$route.name=='worksite-detail'){
              this.item.task_type = 'task-cantiere'
            }
            
            
            this.item.dateString = this.item.startStr
            this.item.dateTmp = moment(this.item.startStr).unix()
            this.item.year = moment().format('YYYY')
            this.item.month = this.item.dateString.split('-')[1]
            this.item.day =  this.item.dateString.split('-')[2]
            this.item.start = moment(this.item.startStr).valueOf()
            this.item.start_tmp = moment(this.item.startStr).unix()
            this.item.end = moment(this.item.endStr).valueOf()
            this.item.end_tmp = moment(this.item.endStr).unix()
            this.item.createdBy = this.datastore.auth.currentUser

            axios.post(`${this.UrlServer}worksites/task/register`, this.item, {headers: { authorization:this.tk}} )
              .then((response)=>{
                let self = this
                if (response.data) {
                  if (this.$route.name=='worksite-detail') {
                    Swal.fire({  
                      title: this.$t("t-msg-task-init") , 
                      text: this.$t("t-msg-task-init-content"), 
                      icon:"success",              
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:5000
                      }).then(()=>{
                        self.getList()
                        self.$router.push('/worksite/detail/'+this.projectID+'/task/'+response.data)
                      });
                  }
                  if (this.$route.name=='support-detail') {
                    Swal.fire({  
                      title: this.$t("t-msg-ticket-init") , 
                      text: this.$t("t-msg-ticket-init-content"), 
                      icon:"success",              
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:5000
                      }).then(()=>{
                        self.getList()
                        self.$router.push('/support/detail/'+this.projectID+'/task/'+response.data)
                      });
                  }

                }
              })

            }
        } else {
          Swal.fire({  
            title: "Dati mancanti", 
            text: "Attenzione: devi inserire tutti i dati obbligatori, a partire dalla 'data di inizio' dei lavori... ", 
            icon:"warning",              
            showCancelButton:false,
            showConfirmButton:false,
            timer:5000
          })
        }
      },
      deletedata(id){
        let self = this
        Swal.fire(
          {
            title: this.$t("t-delete-confirm-setting-title"),
            text: this.$t("t-delete-confirm-setting-text"),
            icon: this.$t("t-delete-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-delete-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-delete-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-delete-confirm-setting-confirmButtonColor"),
            confirmButtonText: this.$t("t-delete-confirm-setting-confirmButtonText")
          }
          ).then((result) => {
            if (result.value) {
              this.axiosInterceptor()
              axios.delete(`${this.UrlServer}tasks/${id}`, {headers: { authorization:this.tk}} )
                .then(()=>{
                 
                  self.getList()       
              })
            }
        });
      },
      setPages() {
        let numberOfPages = Math.ceil(this.allTask.length / this.perPage);
        this.pages = [];
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },
      paginate(responseList) {
        let page = this.page;
        let perPage = this.perPage;
        let from = page * perPage - perPage;
        let to = page * perPage;
        return responseList.slice(from, to);
      },
      paginateAct(pg){
        this.page = pg
        this.getList()
      },
      splitStr(str,car){
        if (str){
          if(str.includes(car)==true) {
            let val =  str.split(car)
            return `${val[0]} ${val[1]}`
          } else{
            return str
          }
        } else {
          return ''
        }
    }
    },
    mounted() {
      this.getList()
    }
  };
</script>

<template>

    <div class="row">
        <div class="col-lg-12">          
            <div class="card-body pl-10 mb-20">
                <template v-if="addTabPlan==false">
                    
                  <template v-if="$route.name=='worksite-detail'">
                    <button class="btn btn-secondary" type="button" @click="addTabPlan=true">
                        +
                        <i class="bx bx-calendar-edit"></i>
                        Pianifica lavorazione giornaliera
                    </button>  
                  </template>
                  <template v-if="$route.name=='support-detail'">
                    <button class="btn btn-info" type="button" @click="addTabPlan=true">
                        +
                        <i class="mdi mdi-account-tie-voice"  title="Servizio di Assistenza"></i>
                        Registra servizio di assistenza
                    </button>  
                  </template>
                </template>
                <template v-else>
                    <button class="btn btn-light" @click="addTabPlan=false">
                        X
                        Chiudi scheda
                    </button> 
                </template>

            </div>
        </div>
    </div>
    <div v-if="addTabPlan">
        <div class="row background-btn-grey-1  mb-50 mt-20 pl-10" >
            <div class="col-md-12 col-lg-2">
                <label for="code-field" class="form-label">
                  <strong v-if="$route.name=='worksite-detail'"> Inizio lavori</strong>  
                  <strong v-if="$route.name=='support-detail'"> Inizio Intervento</strong>  
                  <span class="text-danger">*</span></label>
                    <div class="input-group mb-1">
                        <input type="datetime-local" class="form-control" v-model="item.startStr" :min="minDate" :max="maxDate" @change="updateDate('start')" />
                    </div>
                </div>
                <div class="col-md-12 col-lg-2">
                    <label for="code-field" class="form-label">
                      <strong v-if="$route.name=='worksite-detail'">Fine lavori</strong>  
                      <strong v-if="$route.name=='support-detail'">Fine Intervento</strong> 
                      <span class="text-danger">*</span></label>
                        <div class="input-group mb-1">
                            <input type="datetime-local" class="form-control"  v-model="item.endStr"  :min="minDateEnd" :max="maxDateEnd"  @change="updateDate('end')" />
                        </div>
                </div>
                <div class="col-md-12 col-lg-5"></div>
                <div class="col-md-12 col-lg-2">
                  <div class="input-group mb-1 mt-20">
                    <template v-if="item">
                      <select class="form-select"  v-model="item.status">
                        <option value="">Stato Intervento</option>
                        <option value="init">Avviato</option>
                        <option value="pending">Lavorazione in corso</option>
                        <option value="canceled">Annullato</option>
                        <option value="completed">Completato/Chiuso</option>
                      </select>
                    </template>
                  </div>
                </div>
        </div>
        <div class="row background-btn-grey-1  pl-10" >
            <div class="col-md-12 col-lg-4">
                <label for="code-field" class="form-label"  v-if="$route.name=='worksite-detail'"><strong>Denominazione attività (opzionale)</strong></label>
                <label for="code-field" class="form-label"  v-if="$route.name=='support-detail'"><strong>Denominazione attività di assistenza</strong></label>

                <div class="input-group mb-1">
                    <input type="text" class="form-control" placeholder="Identifica la lavorazione"    v-model="item.name"  />
                </div>
            </div>
        </div>
        <div class="row background-btn-grey-1  pl-10" >
            <div class="col-md-12 col-lg-6">
                <label for="code-field" class="form-label"  v-if="$route.name=='worksite-detail'"><strong>Descrizione generale (opzionale)</strong></label>
                <label for="code-field" class="form-label"  v-if="$route.name=='support-detail'"><strong>Descrizione intervento</strong></label>
                
                <div class="input-group mb-1">
                    <textarea class="form-control" placeholder="Inserisci descrizione" v-model="item.details"  rows="10" ></textarea>
                </div>
            </div>
        </div>
        <div class="row background-btn-grey-1  pl-10" >
            <div class="col-md-12 col-lg-12 text-center pt-10 pb-10">
              <template v-if="$route.name=='worksite-detail'">
                <button class="btn btn-success" type="button" @click="initItem()">
                  <i class="bx bx-save"></i>
                  Crea attività
                </button>  
              </template>
              <template v-else>
                <button class="btn btn-info" type="button" @click="initItem()">
                  <i class="mdi mdi-account-tie-voice"></i>
                  <i class="bx bx-save"></i>
                  Registra attività
                </button>  
              </template>

            </div>
        </div>
    </div>
    <div class="card-header  mb-50">
              <div class="row align-items-center mb-50">
                <div class="col ">
                  <ul class="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active fw-semibold" data-bs-toggle="tab" href="#productnav-all" role="tab">
                        Elenco attività svolte
                        <span class="badge badge-soft-danger align-middle rounded-pill ms-1">{{n_results}}</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-auto">
                  <div id="selection-element">
                    
                  </div>
                </div>
              </div>
            </div>
            <!-- end card header -->
          <div class="card-body">
            
            <div class="table-card mb-1">
              <div class="row">
                <div class="col-lg-12 col-md-12  mb-50">
                  Tipologia di attività:
                    <img src="@/assets/images/imp-cantiere-sm.png" alt="Pianificazione Giornaliera" title="Pianificazione Giornaliera" height="22" /> <strong>Pianificazione Lavorazioni giornaliere</strong>
                     &nbsp; <i class="mdi mdi-account-tie-voice"  title="Servizio di Assistenza"></i> <strong>Servizio di Assistenza (Ticket)</strong>
                </div>
              </div>
                <div class="row thRow ">
                    <div class="col-lg-2 col-md-12">
                        Nome
                    </div>
                    <div class="col-lg-2 col-md-12">
                      Tipologia
                    </div>
                    <div class="col-lg-2 col-md-12">
                      Data inizio
                    </div>
                    <div class="col-lg-2 col-md-12">
                      Data fine
                    </div>
                    <div class="col-lg-1 col-md-12">
                        Stato
                    </div>
                    <div class="col-lg-1 col-md-12 text-right">
                        Azioni
                    </div>
                </div>
                <div class="row list tRow form-check-all d-flex justify-content-end " v-for="(item, index) of responseList" :key="index">
                    <div class="col-lg-2 col-md-12 ">
                        <div class="list-inline hstack gap-2 mb-0">
                            {{ item.name }}
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 ">
                        <div class="list-inline hstack gap-2 mb-0">
                       
                          <template v-if="item.task_type == 'task-cantiere'">
                            <img src="@/assets/images/imp-cantiere-sm.png" alt="Pianificazione Giornaliera" title="Pianificazione Giornaliera" height="22" />
                          </template>
                          <template v-if="item.task_type == 'task-assistenza'">
                            <i class="mdi mdi-account-tie-voice" title="Servizio di Assistenza"></i>
                            
                          </template>
 
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 ">
                        <div class="list-inline hstack gap-2 mb-0">
                            {{ convertDatetime(item.startStr) }}
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12">
                        <div class="list-inline hstack gap-2 mb-0">    
                          {{ convertDatetime(item.endStr) }}
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12">
                      <template v-if="item.status=='completed'">
                        <span class="badge text-uppercase badge-soft-success">Completato</span>
                      </template>
                      <template v-if="item.status=='working'">
                        <span class="badge text-uppercase badge-soft-warning">In lavorazione</span>
                      </template>
                      <template v-if="item.status=='blocked'">
                        <span class="badge text-uppercase badge-soft-danger">Bloccato</span>
                      </template>
                      <template v-else>
                        <span class="badge text-uppercase badge-soft-info">Non avviato</span>
                      </template>
                    </div>
                    <div class="col-lg-2 col-md-12">
                        <ul class="list-inline text-left">
                          <template v-if="item.task_type=='task-cantiere' && $route.name=='worksite-detail'">
                            <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Rimuovi">
                              <a href="javascript:void(0)" class="text-danger d-inline-block remove-item-btn" @click="deletedata(item._id)">
                                <i class="ri-delete-bin-5-fill fs-16"></i>
                              </a>
                            </li>
                          </template>
                          <template v-if="item.task_type=='task-assistenza' && $route.name=='support-detail'">
                            <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Rimuovi">
                              <a href="javascript:void(0)" class="text-danger d-inline-block remove-item-btn" @click="deletedata(item._id)">
                                <i class="ri-delete-bin-5-fill fs-16"></i>
                              </a>
                            </li>
                          </template>

                            <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Modifica">
                              <template v-if="item.task_type=='task-cantiere'">
                                <a href="javascript:void(0)" data-bs-toggle="modal" class="text-primary d-inline-block edit-item-btn" @click="$router.push('/worksite/detail/'+projectID+'/task/'+item._id)">
                                  <i class="ri-pencil-fill fs-16"></i>
                                </a>
                              </template>
                              <template v-if="item.task_type=='task-assistenza'">
                                <a href="javascript:void(0)" data-bs-toggle="modal" class="text-primary d-inline-block edit-item-btn" @click="$router.push('/support/detail/'+projectID+'/task/'+item._id)">
                                  <i class="ri-pencil-fill fs-16"></i>
                                </a>
                              </template>
                            </li>
                        </ul>
                    </div>
                </div>
          </div>

            <div>
              <div class="table-responsive table-card mb-1">

                <div class="noresult" style="display: none">
                  <div class="text-center">
                    <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
                      :height="75" :width="75" />
                    <h5 class="mt-2">{{ $t("t-msg-sorry-no-result") }}</h5>
                    <p class="text-muted mb-0">
                     
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-flex tRow justify-content-end mt-3 mb-50" v-if="n_results > perPage">
                    <div class="col-lg-2 col-md-12 text-right">
                        <label class="pt-2">Risultati per pagina:</label>
                    </div>        
                    <div class="col-lg-1 col-md-12 ">
                        <div class="pl-10 pr-20">
                            <select class="form-select" v-model="perPage" @change="paginateAct(paginated.pre_page)">
                                <option v-for="item in [5,10,20,50,100]" :key="item" :value="item">{{ item}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 ">
                        <div class="pagination-wrap hstack gap-2">
                        <a class="page-item pagination-prev disabled" href="#" v-if="paginated.pre_page > 1" @click="paginateAct(paginated.pre_page)">
                            Precedenti
                        </a>
                        <ul class="pagination listjs-pagination mb-0" >
                            <li :class="{
                                    active: index == page,
                                    disabled: index == '...',
                                    }" v-for="index in paginated.total_pages" :key="index">
                                <a class="page" href="#" @click="paginateAct(index)">{{ index}}</a>
                            </li>
                        
                        </ul>
                        <a class="page-item pagination-next" href="#" @click="paginateAct(paginated.next_page)" >
                        Successivi
                        </a>
                        </div>
                    </div>
          </div> 
            </div>
          </div>
    

    <div class="row">
        <div class="col-lg-12">            
            <div class="card-body">
                <giornaliere />
            </div>
        </div>
    </div>

</template>